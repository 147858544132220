<template>
  <div class="mb-4" v-if="topBrand && topBrand.length">
    <div class="container" v-for="(brand, k) in topBrand" :key="k">
<!--      <a-card style="width: 100%">
        <template slot="title" class="my-0">
          <div class="float-left d-md-flex">
            <div class="">
              <h4 class="font-weight-bold mb-0" style="color: #474545">Top Brands</h4>
            </div>
          </div>
        </template>
        <a-tooltip :title="brand.name" v-for="(brand, k) in topBrand" :key="k">
          <a-avatar :size="74" class="m-2 border top-brand" :src="showImage(brand.logo)"/>
        </a-tooltip>
      </a-card>-->
      <a-card class="mt-4" style="width: 100%">
        <template slot="title" class="my-0">
          <div class="float-left d-md-flex">
            <div class="">
              <h4 class="font-weight-bold mb-0" style="color: #474545">{{  brand.name }}</h4>
            </div>
          </div>
        </template>
        <a-row :gutter="10">
          <a-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4" class="product-detail-section mt-4"
                 v-for="(products, k) in brand.products" :key="k">
            <Product :products="products"/>
          </a-col>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Product from "@/components/helper/Product.vue";

export default {
  name: "Brand",
  components: {Product},
  computed: {
    ...mapGetters(["topBrand"]),
  }
}
</script>

<style scoped>
.top-brand {
  cursor: pointer;
}

.top-brand:hover {
  box-shadow: 0 0 17px 1px #00000024;
}
</style>
